import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import PageTitle from "../../components/Common/PageTitle";
import {
  ObjectHasValues,
  SetStatusStyle,
  ShowNotification,
} from "../../helpers/general/generalHelpers";
import {
  getPaginationInformation,
  initialPaginationObject,
} from "../../helpers/general/pagination";
import Loader from "../../shared/loader/loader";
import { getAuthCodes } from "./services/AuthCodes-service";

const AuthCodesList = (props) => {
  const [isTargetElementVisible, setIsTargetElementVisible] = useState(true);
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: "success",
      message: notification.message,
    });
    props.location.notification = null;
  }

  useEffect(() => {
    setLoader(true);
    getAuthCodes(currentPage, filters)
      .then((response) => {
        console.log("AuthCodes", response.data)
        const coupons = response.data.authCodes;
        // setCoupons(JSON.parse(coupons));
        setCoupons(coupons);
        setPagination(
          getPaginationInformation(response.data.totalCount, currentPage)
        );
        setIsTargetElementVisible(true);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters, isDelete, isTargetElementVisible]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle
            title="Auth Codes"
            // button
            name="+ Add New Coupon"
            url="/coupon"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="mobileNumber-filter">Mobile Number</Label>
                          <AvField
                            name="mobileNumber"
                            placeholder="Enter Mobile Number"
                            type="text"
                            className="form-control filter-field"
                            id="mobileNumber-filter"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="isValidated-filter">Is Validated</Label>
                          <AvField
                            type="select"
                            name="isValidated"
                            id="isValidated-filter"
                            className="form-control filter-field"
                          >
                            <option value="">Select Status</option>
                            <option value="true">Validated</option>
                            <option value="false">Not Validated</option>
                          </AvField>
                        </FormGroup>
                      </Col>

                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="email-filter">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Enter Email"
                            type="text"
                            className="form-control filter-field"
                            id="email-filter"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="firstName-filter">First Name</Label>
                          <AvField
                            name="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            className="form-control filter-field"
                            id="firstName-filter"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="lastName-filter">Last Name</Label>
                          <AvField
                            name="lastName"
                            placeholder="Enter Last Name"
                            type="text"
                            className="form-control filter-field"
                            id="lastName-filter"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-sm-12 col-md-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <div className="table-responsive">
                    <Table className='table-centered table-nowrap table-hover'>
                      <thead className='thead-light font-size-15 '>
                      <tr>
                        <th scope='col'>Auth Code</th>
                        <th scope='col'>Validation</th>
                        <th scope='col'>Mobile Number</th>
                        <th scope='col'>First Name</th>
                        <th scope='col'>Last Name</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Generated Date</th>
                      </tr>
                      </thead>
                      <tbody className='agora-lato'>
                      {coupons.map((coupon, i) => (
                        <tr key={'_coupon_' + i}>
                          <td>{coupon.code}</td>
                          <td>{coupon.isValidated ? 'Validated' : 'Not Validated'}</td>
                          <td>{coupon.mobileNumber}</td>
                          {coupon.userId?.firstName === undefined || coupon.userId?.firstName === null ? (
                            <td><span style={{ color: 'red' }}>Undefined</span></td>
                            ) : (
                            <td>{coupon.userId?.firstName}</td>
                          )}
                          {coupon.userId?.lastName === undefined || coupon.userId?.lastName === null ? (
                            <td><span style={{ color: 'red' }}>Undefined</span></td>
                            ) : (
                            <td>{coupon.userId?.lastName}</td>
                          )}
                          {coupon.userId?.email === undefined || coupon.userId?.email === null ? (
                            <td><span style={{ color: 'red' }}>Undefined</span></td>
                            ) : (
                            <td>{coupon.userId?.email}</td>
                          )}
                          <td>
                            <div>
                              <SetStatusStyle
                                status={coupon.isValidated ? 'Active' : 'Inactive'}
                              />
                            </div>
                          </td>
                          <td>
                            {new Intl.DateTimeFormat('en-GB', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit'
                            }).format(new Date(coupon.createdAt))}
                          </td>
                        </tr>
                      ))}
                      </tbody>

                    </Table>
                  </div>
                  <Row>
                    <Col lg='12'>
                      <Pagination className='pagination pagination-rounded justify-content-center mt-4'>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem
                            key={'_page_' + index}
                            active={page === pagination.currentPage}
                          >
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong>{" "}
                          to <strong>{pagination.endIndex + 1}</strong> of{" "}
                          <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuthCodesList;
