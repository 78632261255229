import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import {
  ShowNotification,
  GetQueryParam,
  GetFilterString,
} from "../../../helpers/general/generalHelpers";
import { appConfig } from "../../../config/config";


function getAuthCodes(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/auth/AuthCodes/?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

export { getAuthCodes };
